import { DateSelectorMode } from '@/components/template/Analysis/Selector/DateSelector'
import { scopesCategoriesApi } from '@/ghgApi'
import { EmissionFactorTableNode, Site } from '@/openapi'
import { GetState, SetState } from 'zustand'

export type Date = {
    year: number
    half: number | null
    quarter: number | null
    month: number | null
    compareYear: number
}

export type AnalysisState = {
    isLoaded: boolean
    date: Date
    dateSelector: {
        mode: DateSelectorMode
        options: {
            years: Array<number>
            half: Array<number>
            quarter: Array<number>
            months: Array<number>
        }
    }
    selectedSite?: Site
    sites?: Array<Site>
    detailCategoryTitle?: string // Scope3詳細ボタン
    detailCategoryId?: number
}

export interface TAnalysisState {
    tabValue: number
    analysisState: AnalysisState
    selectedCategory: number
    detailEmissionFactorId: number
    scopeName: string
    parentNameInDetailScope: string
    emissionFactorTableNodes: EmissionFactorTableNode[]
    setAnalysisState: (analysisState: AnalysisState) => void
    setSelectedCategory: (id: number) => void
    setParentNameInDetailScope: (name: string) => void
    setTabValue: (tabId: number) => void
    setDetailEmissionFactorId: (id: number) => void
    setScopeName: (name: string) => void
    resetEmmisionFactor: () => void
    fetchEmissionFactorTableNodes: () => void
}

export const initialValueAnalysis: AnalysisState = {
    isLoaded: false,
    date: {
        year: 0,
        half: null,
        quarter: null,
        month: null,
        compareYear: 0,
    },
    dateSelector: {
        mode: 'year' as DateSelectorMode.YEAR,
        options: {
            years: [],
            half: Array.from({ length: 2 }, (_, i) => i + 1),
            quarter: Array.from({ length: 4 }, (_, i) => i + 1),
            months: Array.from({ length: 12 }, (_, i) => i + 1),
        },
    },
}

export const createAnalysisSlice = (set: SetState<TAnalysisState>, get: GetState<TAnalysisState>): TAnalysisState => {
    return {
        analysisState: initialValueAnalysis,
        tabValue: 0,
        selectedCategory: 3,
        detailEmissionFactorId: 0,
        scopeName: '',
        emissionFactorTableNodes: [],
        parentNameInDetailScope: '',

        setAnalysisState: (analysisState: AnalysisState) => {
            set({ analysisState })
        },
        setSelectedCategory: (id: number) => {
            set({ selectedCategory: id })
        },
        setTabValue: (tabId: number) => {
            set({ tabValue: tabId })
        },
        setParentNameInDetailScope: (name: string) => {
            set({ parentNameInDetailScope: name })
        },
        setDetailEmissionFactorId: (id: number) => {
            set({ detailEmissionFactorId: id })
        },
        setScopeName: (name: string) => {
            set({ scopeName: name })
        },
        resetEmmisionFactor: () => {
            set({ scopeName: '', detailEmissionFactorId: 0, parentNameInDetailScope: '', })
        },
        fetchEmissionFactorTableNodes: async () => {
            const res = await scopesCategoriesApi.getEmissionFactorTableNodes()
            set({ emissionFactorTableNodes: res.data.data })
        },
    }
}
