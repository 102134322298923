const cache = {
    set(key: string, value: string) {
        if (typeof window !== 'undefined') return localStorage.setItem(key, value)
    },
    get(key: string) {
        if (typeof window !== 'undefined') return localStorage.getItem(key)
    },
    setJson(key: string, valueJson: any) {
        if (typeof window !== 'undefined') return localStorage.setItem(key, JSON.stringify(valueJson))
    },
    getJson(key: string) {
        if (typeof window !== 'undefined')
            return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : null
    },
    remove(key: string) {
        if (typeof window !== 'undefined') return localStorage.removeItem(key)
    },
}

export default cache
