import { antiWarmingReportApi, organizationApi, siteApi, userApi, Year } from '@/ghgApi'
import {
    Objective,
    Objectives,
    Organization,
    AntiWarmingReport,
    SiteList,
    SiteObjective,
    SiteObjectives,
    User,
    UserList,
    AntiWarmingOriginateCreate,
} from '@/openapi'
import Auth, { CognitoUser } from '@aws-amplify/auth'

interface accType {
    objectives: Objective | undefined
    siteId: number
}

export const getAllStoreApi = async () => {
    const user = await getUser()
    const organization = await getOrganization(user.organizationId)
    const organizationObjective = await getOrganizationObjective()
    const organizationSites = await getOrganizationSites()
    const organizationSitesObjective = await getOrganizationSitesObjective()
    return {
        user,
        organization,
        organizationObjective,
        organizationSites,
        organizationSitesObjective,
    }
}

export const findOrganizationSitesObjectives = (sites: Array<SiteObjective>, year: Year) => {
    const sitesMap = sites.reduce((acc: { [id: number]: accType }, curr) => {
        if (curr.objectives)
            acc[curr.siteId] = {
                ...curr,
                objectives: curr.objectives.find((objective) => objective.year === year),
            }
        return acc
    }, {})
    return sitesMap
}

export const findOrganizationObjectives = (objectives: Array<Objective>, year: number) => {
    return objectives.find((objective) => objective.year === year) || { year, text: '' }
}

export const getOrganizationSitesObjective = () => {
    const promise = new Promise<SiteObjectives>((resolve, reject) => {
        organizationApi
            .getOrganizationSitesObjectives()
            .then((res) => resolve(res.data))
            .catch((err) => console.log(err))
    })
    return promise
}

export const getOrganizationSites = () => {
    const promise = new Promise<SiteList>((resolve, reject) => {
        siteApi
            .listSite(undefined, undefined, 1000)
            .then((res) => resolve(res.data))
            .catch((err) => console.log(err))
    })
    return promise
}

export const getOrganizationObjective = () => {
    const promise = new Promise<Objectives>((resolve, reject) => {
        organizationApi
            .getOrganizationObjectives()
            .then((res) => resolve(res.data))
            .catch((err) => console.log(err))
    })
    return promise
}

export const getOrganization = (id: number) => {
    const promise = new Promise<Organization>((resolve, reject) => {
        organizationApi
            .getOrganization(id)
            .then((res) => resolve(res.data))
            .catch((err) => console.log(err))
    })
    return promise
}

export const getUser = () => {
    const promise = new Promise<User>((resolve, reject) => {
        Auth.currentAuthenticatedUser()
            .then((congitoUser: CognitoUser) =>
                userApi
                    .getUser(congitoUser.getUsername())
                    .then((res) => resolve(res.data))
                    .catch((err) => console.log(err)),
            )
            .catch((err) => console.log(err))
    })
    return promise
}

export const getListUser = (search?: string, role?: number, offset?: number, limit?: number) => {
    const promise = new Promise<UserList>((resolve, reject) => {
        userApi
            .listUser(search, role, offset, limit)
            .then((res) => resolve(res.data))
            .catch((err) => console.log(err))
    })
    return promise
}

export const deleteUser = (userId: string) => {
    const promise = new Promise<void>((resolve, reject) => {
        userApi
            .deleteUser(userId)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response))
    })
    return promise
}

export const getAntiWarmingReport = (search?: string, year?: number) => {
    const promise = new Promise<AntiWarmingReport[]>((resolve, reject) => {
        antiWarmingReportApi
            .getAntiWarmingReport(search, year)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response))
    })
    return promise
}

export const createAntiWarmingOriginate = (params: AntiWarmingOriginateCreate) => {
    const promise = new Promise((resolve, reject) => {
        antiWarmingReportApi
            .createAntiWarmingOriginate(params)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response))
    })
    return promise
}
