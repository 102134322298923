export const ROUTES = {
    LOGIN: '/accounts/login',
    FORGOT_PASSWORD: '/accounts/reset-password',
    PRIVACY_POLICY: '/info/privacy-policy',
    PRIVACY_POLICY_FULL: '/info/privacy-policy-full',
    NOT_FOUND: '/404',
    MAINTENANCE: 'https://scopex.tb-m.com/maintenance',

    // dasboard
    HOME: '/',
    INPUT_MONTH: '/input/months',
    INPUT_SCOPES: '/input/scopes',
    INPUT_SALES: '/input/sales',
    ANALYSIS_ORGANIZATION: '/analysis/organization',
    ANALYSIS_SITE: '/analysis/sites',

    // managements
    PLANS: '/management/plans',
    ACCOUNTS_SETTING: '/management/accounts',
    ACCOUNTS_FORM: '/management/accounts/form',
    COMPANY_SETTING: '/management/companies',

    // mfa
    MFA_DEVICE_REGISTRATION: '/mfa/device-registration',
    MFA_DEVICE_REGISTRATION_FORCE: '/mfa/device-registration/force',

    // mock
    INPUT_NEW: '/input/new',
    MORE: '/more',
    TREE: '/tree',

    // Reporting
    ANTI_WARMING: '/reporting/anti-warming',
    ENERGY_SAVING: '/reporting/energy-saving',
}

export const PRIVATE_ROUTES = {
    viewer: [
        ROUTES.LOGIN,
        ROUTES.FORGOT_PASSWORD,
        ROUTES.PRIVACY_POLICY,
        ROUTES.NOT_FOUND,
        ROUTES.MAINTENANCE,
        ROUTES.HOME,
        ROUTES.ANALYSIS_SITE,
        ROUTES.ANALYSIS_ORGANIZATION,
        ROUTES.INPUT_NEW,
        ROUTES.MORE,
        ROUTES.TREE,
        ROUTES.ANTI_WARMING,
        ROUTES.ENERGY_SAVING,
        ROUTES.MFA_DEVICE_REGISTRATION,
    ],
    general: [
        ROUTES.LOGIN,
        ROUTES.FORGOT_PASSWORD,
        ROUTES.PRIVACY_POLICY,
        ROUTES.NOT_FOUND,
        ROUTES.MAINTENANCE,
        ROUTES.HOME,
        ROUTES.ANALYSIS_SITE,
        ROUTES.INPUT_MONTH,
        ROUTES.INPUT_SCOPES,
        ROUTES.INPUT_NEW,
        ROUTES.ANTI_WARMING,
        ROUTES.ENERGY_SAVING,
        ROUTES.MFA_DEVICE_REGISTRATION,
    ],
    admin: Object.values(ROUTES),
}
