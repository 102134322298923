import { User } from '@/openapi'
import { GetState, SetState } from 'zustand'

export interface TManagementSlice {
    managementUser: User | null
    setManagementUser: (user: User | null) => void
}

export const createManagementSlice = (set: SetState<TManagementSlice>, get: GetState<TManagementSlice>) => {
    return {
        managementUser: null,
        setManagementUser: (user: User | null) => {
            set({ managementUser: user })
        },
    }
}
