import { PLAN_ID } from '@/constant/options'
import { ROLE } from '@/constant/role'
import { scopesCategoriesApi } from '@/ghgApi'
import { Objective, Organization, Scope, Site, User } from '@/openapi'
import { findOrganizationObjectives, findOrganizationSitesObjectives, getAllStoreApi } from '@/services/store'
import cache from '@/utils/cache'
import { getTime } from '@/utils/nendo'
import { GetState, SetState } from 'zustand'

export type StoreState = {
    user: User | null
    organization: OrganizationWithObjective | null
    sites: Site[] | SiteWithObjective[]
    selectedSite: Site | null
}
export interface OrganizationWithObjective extends Organization {
    objectives?: Objective
}
export interface SiteWithObjective extends Site {
    objectives?: Objective
}

export type UpdatedSite = {
    id: number
    year: number
    text: string
}

export interface TStoreState {
    storeState: StoreState
    reUpdate: boolean
    disabledScope3: boolean
    isAdmin: boolean
    scopeData: Scope[]
    isShowAllCompanyOption: boolean
    expandedMenu: boolean
    fetchStoreData: (site?: Site) => void
    setSelectedSite: (site: Site) => void
    setReUpdate: () => void
    setSite: (sites: Site[] | SiteWithObjective[]) => void
    setOrganization: (organization: StoreState['organization']) => void
    setStoreState: (storeState: StoreState) => void
    setExpandedMenu: (state: boolean) => void
    setOrganizationObjective: (year: number, text: string) => void
    setSiteObjectives: (updatedSite: UpdatedSite[]) => void
}

export const initialValueStoreState = {
    user: null,
    organization: null,
    sites: new Array<Site>(),
    selectedSite: null,
}

export const createStoreSlice = (set: SetState<TStoreState>, get: GetState<TStoreState>) => ({
    storeState: initialValueStoreState,
    reUpdate: false,
    disabledScope3: true,
    isAdmin: false,
    isShowAllCompanyOption: false,
    scopeData: [],
    expandedMenu: true,
    fetchStoreData: async (site?: Site) => {
        const { user, organization, organizationObjective, organizationSites, organizationSitesObjective } =
            await getAllStoreApi()
        const {
            data: { scopes: scopeData },
        } = await scopesCategoriesApi.getScopes()
        const { year, month } = getTime()
        const isLarger = organization.startMonth <= month
        const currYear = isLarger ? year : year - 1
        const currentOrganiObjective = findOrganizationObjectives(organizationObjective.objectives, currYear)
        const sitesMap = findOrganizationSitesObjectives(organizationSitesObjective.sites, currYear)
        const sites = organizationSites.data ? organizationSites.data : []
        const storeState = {
            user,
            organization: { ...organization, objectives: currentOrganiObjective },
            sites: sites.map((d) => Object.assign(d, sitesMap[d.id])),
            selectedSite: site || (organizationSites.data ? organizationSites.data[0] : null),
        }
        const disabledScope3 = organization.planId === PLAN_ID.starter
        const isAdmin = user.roleId === ROLE.admin
        const isShowAllCompanyOption =
            user.roleId !== ROLE.general || storeState.sites.length >= (organization.siteNumber as number)
        set({ storeState, disabledScope3, isAdmin, scopeData, isShowAllCompanyOption })
        // TODO: to be improved
        cache.set('startMonth', organization.startMonth.toString())
    },
    setReUpdate: () => {
        set({ reUpdate: !get().reUpdate })
    },
    setSelectedSite: (site: Site) => {
        set({ storeState: { ...get().storeState, selectedSite: site } })
    },
    setSite: (sites: Site[] | SiteWithObjective[]) => {
        set({ storeState: { ...get().storeState, sites } })
    },
    setOrganization: (organization: OrganizationWithObjective) => {
        set({ storeState: { ...get().storeState, organization } })
    },
    setStoreState: (storeState: StoreState) => {
        set({ storeState })
    },
    setExpandedMenu: (state: boolean) => {
        set({ expandedMenu: state })
    },
    setOrganizationObjective: (year: number, text: string) => {
        const storeData = get().storeState
        const objectives = { text, year }
        const organization = { ...storeData.organization, objectives } as OrganizationWithObjective
        set({ storeState: { ...storeData, organization: organization } })
    },
    setSiteObjectives: (updatedSite: UpdatedSite[]) => {
        const storeData = get().storeState
        const sites = storeData.sites as (Site & { objectives: Objective })[]
        sites.forEach((site) => {
            updatedSite.forEach((updateSite) => {
                if (site.id === updateSite.id) {
                    site.objectives.year = updateSite.year
                    site.objectives.text = updateSite.text
                }
            })
        })
        set({ storeState: { ...storeData, sites } })
    },
})
