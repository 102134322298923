import { InputStatus } from '@/ghgApi'
import { Category, MonthlySiteSummary } from '@/openapi'
import { nendo } from '@/utils/nendo'
import { InputTreeActivity, InputTreeLeafNode, InputTreeNode } from '@/utils/tree'
import { ReactNode } from 'react'
import { GetState, SetState } from 'zustand'

type InputScopeState = {
    date: InputDate
    summary: MonthlySiteSummary | null
    hasChanges: boolean
    tabValue: number
    inputStatus: { [categoryId: number]: InputStatus | null }
}

export type InputDate = {
    month: number
    year: number
}

export enum ScopeInputState {
    COMPLETE = '完了',
    INCOMPLETE = '未入力',
    NONE = '-',
}

export type InputTreeState = {
    categoryId: Category['id'] | null
    tree: InputTreeNode | null
    selected: Array<InputTreeLeafNode>
    complete: boolean
}
export type InputTreeProviderProps = {
    children: ReactNode
    categoryId: Category['id']
    tree: InputTreeNode
}

type activitiesScopes = { 1: InputTreeActivity[]; 2: InputTreeActivity[]; 3: InputTreeActivity[] }

export interface TInputSlice {
    inputScopeState: InputScopeState
    inputTreeState: InputTreeState
    activities: activitiesScopes
    setActivities: (activities: activitiesScopes) => void
    setInputScopeState: (values: InputScopeState) => void
    setInputTreeState: (values: InputTreeState) => void
}

export const createInputSclie = (set: SetState<TInputSlice>, get: GetState<TInputSlice>): TInputSlice => {
    // const dateSessionStorage =
    //     typeof window !== 'undefined'
    //         ? JSON.parse(window.sessionStorage.getItem(SessionStorageKey.DATA_INPUT_DATE) || '')
    //         : { month: 1, year: nendo() }

    const inputScopeStateInitialValue: InputScopeState = {
        // date: dateSessionStorage!,
        date: { month: 1, year: nendo() },
        summary: null,
        hasChanges: false,
        tabValue: 0,
        inputStatus: {
            // Scope 1
            1: null,
            // Scope 2
            2: null,
            // Scope 3
            3: null,
            4: null,
            5: null,
            6: null,
            7: null,
            8: null,
            9: null,
            10: null,
            11: null,
            12: null,
            13: null,
            14: null,
            15: null,
            16: null,
            // TODO: Set default to NoAnswer when scope 15 data is available
            17: InputStatus.NOT_NEEDED,
        },
    }
    const inputTreeStateInitialValue: InputTreeState = {
        categoryId: null,
        tree: null,
        selected: [],
        complete: false,
    }

    return {
        inputScopeState: inputScopeStateInitialValue,
        inputTreeState: inputTreeStateInitialValue,
        activities: { 1: [], 2: [], 3: [] },
        setInputScopeState: (values: InputScopeState) => {
            set({ inputScopeState: values })
        },
        setInputTreeState: (values: InputTreeState) => {
            set({ inputTreeState: values })
        },
        setActivities: (activities: activitiesScopes) => {
            set({ activities })
        },
    }
}
