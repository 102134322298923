import { ROUTES } from '@/routes'
import { ROLE } from './role'
import { generateYears } from '@/utils/nendo'

export const PLAN_TYPE = {
    starter: 'スターター',
    basic: 'ベーシック',
    enterprise: 'エンタープライズ',
    trial: 'トライアル',
}
export const PLAN_ID = {
    starter: 1,
    basic: 2,
    enterprise: 3,
    trial: 4,
}

export const PLAN_OPTIONS = [
    { id: PLAN_ID.starter, value: PLAN_TYPE.starter },
    { id: PLAN_ID.basic, value: PLAN_TYPE.basic },
    { id: PLAN_ID.enterprise, value: PLAN_TYPE.enterprise },
    { id: PLAN_ID.trial, value: PLAN_TYPE.trial },
]

export const SETTING_OPTIONS = [
    { path: ROUTES.ACCOUNTS_SETTING, text: 'ユーザー一覧' },
    { path: ROUTES.COMPANY_SETTING, text: '企業情報' },
    { path: ROUTES.PLANS, text: 'プラン情報' },
]

export const ROLE_OPTIONS = [
    { id: ROLE.admin, value: '管理者' },
    { id: ROLE.viewer, value: '閲覧者' },
    { id: ROLE.general, value: '一般' },
]

export const YEAR_OPTIONS = generateYears()
