enum Color {
    black = '#333333', // テキスト・ヘッダー背景
    grey = '#C2C2C2', // 非活性時
    greyText = '#707070',
    lightGrey1 = '#F6F6F6', // サイドメニュー背景
    lightGrey2 = '#FCFCFC', // 背景色
    white = '#FFFFFF', // テキスト・背景色
    accent = '#76ABCC', // ボタン
    alert = '#CC7F7F', // ボタン・テキスト
    required = '#F0D8D8', // 必須入力
    border = '#D5D5D5',
    border2 = '#CCCCCC',

    primary = '#5B8AAD',
    primaryLight = '#74A1C3',
    primaryLight2 = '#74A1C333',

    error = '#CE0000',
    success = '#06A800',
    successBtn = '#11A05E',
    successBtnHover = '#00B15E',

    // update november 2022
    lightGray = '#F7F7F7',
    lightGray2 = '#BDBFC8', // border Input
    primaryN = '#42526E', // border Top
    errorN = '#C41415', // border Top
    placeholder = '#868D96', // placeholder
    expandBackground = '#EDF2F5', // expand background
    accordionBackground = '#E5E5E5',
    borderColor = '#DBDBDB',
    tabColor = '#9DA3B5',
    disabledBtn = '#BABABA',
    danger = '#B90101',
    black2 = '#222222',
}

export default Color
