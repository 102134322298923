import { GetState, SetState } from 'zustand'

export type TType = 'success' | 'error' | 'warning'
export type TMessage = { message: string; type: TType }

export interface TMessageSlice {
    message: TMessage | null
    setMessage: (message: TMessage | null) => void
}

export const createMessageSlice = (set: SetState<TMessageSlice>, get: GetState<TMessageSlice>): TMessageSlice => {
    return {
        message: null,
        setMessage: (message: TMessage | null) => {
            set({ message })
        },
    }
}
